import {
  createTheme,
  Button,
  TextField,
  BottomNavigation,
  BottomNavigationAction,
  IconButton,
  Typography,
  Grid,
  Card,
  CardMedia,
  Dialog,
  FormLabel,
  Container,
  Popover,
} from "@mui/material";
import { styled } from "@mui/system";

const theme = createTheme();

export const CustomContainer = styled("div")({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(5, 0, 6),
});

export const Root = styled("div")({
  backgroundColor: "#EAE7E7",
  height: "100vh",
  padding: theme.spacing(5, 0, 6),
});

export const Logo = styled("div")({
  marginTop: "100px",
  marginBottom: "60px",
});

export const WelcomeContainer = styled("div")({
  marginBottom: "32px",
});

export const Title = styled("div")({
  marginTop: "50px",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: "700",
  fontStyle: "normal",
  lineHeight: "36px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#1C1C1C",
  flex: "none",
  height: "36px",
  order: 0,
  flexGrow: 0,
});

export const TitleTypography = styled(Typography)({
  marginTop: "30px",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: "700",
  fontStyle: "normal",
  lineHeight: "36px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#1C1C1C",
  flex: "none",
  height: "36px",
  order: 0,
  flexGrow: 0,
});

export const SubTitleTypography = styled(Typography)({
  fontFamily: "poppins",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "21px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "#6C6C6C",
});

export const InputLabelFormLabel = styled(FormLabel)({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "17px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#1C1C1C",
});

export const InputLabelTypography = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "17px",
  letterSpacing: "0px",
  textAlign: "left",
  color: "#1C1C1C",
});

export const PhoneNumInputBox = styled(TextField)({
  "& input::placeholder": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "130%",
    letterSpacing: "5px",
    alignItems: "center",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "Poppins",
    fontSize: "20px",
    paddingTop: "12px",
    paddingBottom: "12px",
    letterSpacing: "5px",
  },
  "& input[type=date]": {
    fontFamily: "Poppins",
    color: "#1C1C1C",
  },

  height: "52px",
  fontFamily: "Poppins",
  borderRadius: "12px",
  display: "flex",
  marginBottom: "10px",
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "lightgrey",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgrey",
  },
});

export const InputBox = styled(TextField)(({ isExists }) => ({
  borderRadius: "10px",
  "& input::placeholder": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "130%",
    textTransform: "capitalize",
    alignItems: "center",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "Poppins",
    fontSize: "14px",
    paddingTop: "14px",
    paddingBottom: "16px",
    alignItems: "center",
    color: isExists ? "#E52D3C" : "",
  },
  "& input[type=date]": {
    fontFamily: "Poppins",
    color: "#1C1C1C",
  },
  fontFamily: "Poppins",
  display: "flex",
  marginBottom: "10px",
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "lightgrey",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgrey",
    borderRadius: "10px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
  },
}));

export const OtpContainer = styled("div")({
  marginTop: "100px",
});

export const EnterOTPCode = styled(Typography)({
  fontFamily: "poppins",
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "36px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "rgba(28, 28, 28, 1)",
  height: "36px",
});

export const CustomText = styled(Typography)({
  fontFamily: "poppins",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
  letterSpacing: "0px",
  textAlign: "center",
  color: "#6C6C6C",
  height: "42px",
});

export const OtpInputContainer = styled(Grid)({
  marginTop: "40px",
  marginBottom: "20px",
  justifyContent: "center",
  borderRadius: "4px",
  border: "1px",
});

export const BackButton = styled(IconButton)({
  position: "absolute",
  top: 0,
  left: 0,
});

export const CustomButton = styled(Button)(({ theme, disabled }) => ({
  marginTop: "80px",
  height: "52px",
  borderRadius: "10px",
  gap: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px",
  "&:disabled": {
    opacity: 0.6, // You can adjust the opacity for better visual indication
    cursor: "not-allowed",
  },
  "&:hover": {
    opacity: 0.9,
    // Apply hover styles directly to the button
    // Adjust the opacity as needed
  },
}));

export const OTPinputBox = styled(TextField)({
  width: "48px",
  height: "48px",
  borderRadius: "4px",
  border: "1px",
  margin: "0 5px",
  boxShadow: "0px 4px 8px 0px rgba(247, 0, 0, 0.10)",
  color:
    "linear-gradient(0deg, #BDBDBD, #BDBDBD), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "lightgrey",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgrey",
  },
});

export const OTPinputTextField = styled(TextField)({
  width: "60%",
  height: "48px",
  borderRadius: "4px",
  border: "1px",
  margin: "0 5px",
  fontFamily: "Poppins",
  fontSize: "50px",
  boxShadow: "0px 4px 8px 0px rgba(247, 0, 0, 0.10)",
  color:
    "linear-gradient(0deg, #BDBDBD, #BDBDBD), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  "& .MuiOutlinedInput-input": {
    fontFamily: "Poppins",
    fontSize: "20px",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingLeft: "25px",
    paddingRight: "25px",
    letterSpacing: "10px",
    textAlign: "center",
  },
  "&:hover .MuiOutlinedInput-input": {
    borderColor: "lightgrey",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgrey",
  },
});

export const ResendMsg = styled(Grid)({
  fontFamily: "Poppins",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "21px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "#606268",
  height: "21px",
});

export const SkipForNow = styled("div")({
  fontFamily: "inter",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "17px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "rgba(0, 0, 0, 1)",
  textDecoration: "underline",
  cursor: "pointer",
});

export const SuccessContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

export const SuccessMsg = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "24px",
  fontWeight: "600",
  lineHeight: "29px",
  letterSpacing: "0px",
  textAlign: "center",
  color: "#1C1C1C",
  marginTop: "40px",
});

export const SuccessSubMsg = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "25px",
  letterSpacing: "0.01em",
  textAlign: "center",
  color: "#3C3C3C",
  marginTop: "12px",
});

export const TeachingImg = styled("img")({
  marginTop: "50px",
  width: "500px",
  height: "400px",
  flexShrink: 0,
  marginLeft: "0px",
});

export const NavIconContainer = styled(BottomNavigation)({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#000000",
  borderRadius: "24px 24px 0px 0px",
  maxWidth: "sm",
  width: "100%",
});

export const NavIcon = styled(BottomNavigationAction)({
  "&.Mui-selected": {
    color: "#FFFFFF",
    "& .MuiBottomNavigationAction-label": {
      color: "#FFFFFF",
    },
  },
  "&.Mui-disabled": {
    color: "#6C6C6C",
    "& .MuiBottomNavigationAction-label": {
      color: "#6C6C6C",
    },
  },
});

export const CustomerHomeRoot = styled(Container)({
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(2, 0),
  maxWidth: theme.breakpoints.values.sm,
});

export const AvatarButtonContainer = styled("div")({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
});

export const CategoryScrollContainer = styled("div")({
  display: "flex",
  overflowX: "auto",
  padding: "10px",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  "-ms-overflow-style": "none",
  "scrollbar-width": "thin",
  "-webkit-overflow-scrolling": "touch",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "transparent",
  },
});

export const CategoryCard = styled("div")({
  minWidth: "100px",
  height: "120px",
  marginRight: "10px",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});

export const CategoryMedia = styled(CardMedia)({
  width: "50px",
  height: "50px",
  margin: "auto",
  marginTop: "15px",
  top: "9.9px",
  left: "171px",
  borderRadius: "20px",
});

export const VendorCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  marginBottom: "20px",
});

export const VendorMedia = styled(CardMedia)({
  height: 200,
});

export const VendorContent = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
});

export const VendorInfoDialogBox = styled(Dialog)({
  padding: "24px, 0px, 60px, 0px",
  borderRadius: "24px",
});

export const VendoritemCreationOptionInfoDialogBox = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "566px",
    height: "auto",
    padding: "0px",
    borderRadius: "24px",
    overflow: "auto",
  },
});

export const VendorWebViewInfoDialogBox = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "500px",
    height: "auto",
    padding: "0px",
    borderRadius: "24px",
  },
});

export const CustomerProfileDialogBox = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "430px",
    padding: "24px 0px 60px 0px",
    borderRadius: "24px",
  },
});

export const CustomerProfileBottomButtonFrame = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  justifyContent: "center",
  bottom: "10px",
  position: "fixed",
});

export const CustomerProfileBottomButtons = styled(Button)({
  display: "flex",
  height: "44px",
  alignSelf: "stretch",
  borderRadius: "8px",
  padding: "10px",
  margin: "10px",
  cursor: "pointer",
  //alignContent:"center",
  justifyContent: "center",
  textTransform: "capitalize",
  "&:disabled": {
    opacity: 0.6, // You can adjust the opacity for better visual indication
    cursor: "not-allowed",
  },
});

export const CustomerProfileDetailChangeButton = styled(Button)({
  width: "100px",
  maxHeight: "40px",
  // marginTop: "10%",
  // marginBottom: "5%",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  backgroundColor: "#000000",

  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "100",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
});

export const CustomerProfileDetailUploadButton = styled(Button)({
  width: "10px",
  padding: "0px",
  minHeight: "40px",
  // marginTop: "10%",
  // marginBottom: "5%",
  paddingTop: "5px",
  paddingBottom: "5px",
  // paddingLeft: "5px",
  // paddingRight: "5px",
  borderRadius: "4px",
  backgroundColor: "green",
  margin: "0px",

  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "100",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
});

export const VendorDialogBoxButton = styled(Button)({
  width: "100%",
  marginTop: "4%",
  paddingTop: "15px",
  paddingBottom: "15px",
  paddingLeft: "30px",
  paddingRight: "30px",
  borderRadius: "8px",
  // backgroundColor: "#FFFFFF",

  // color: "#000000",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px", // 112.5%
  textTransform: "none",
});

export const ProfilePhotoContainer = styled(Typography)({
  justifyContent: "center",
  display: "flex",
  overflow: "hidden",
  marginBottom: "10px",
  flexDirection: "row",
  alignItems: "center",
  paddingLeft: "0px",
  paddingRight: "0px",
});

export const ErrorNo = styled(Typography)({
  color: "#FF0000",
  fontSize: 60,
  fontFamily: "Plus Jakarta Sans",
  fontWeight: "800",
  wordWrap: "break-word",
});

export const ErrorPageBottomButtonFrame = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "90%",
  // padding: '16px',
  gap: "16px",
  // flexShrink: '0',
  alignItems: "center",

  // justifyContent: "space-between",
  marginTop: "auto",
  // bottom:"0px"
});

export const ErrorPageBottomButtons = styled("div")({
  height: "44px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  padding: "10px",
  cursor: "pointer",
});

export const VenderStatusScreenBottomButtons = styled("div")({
  height: "44px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  padding: "10px",
  cursor: "pointer",
  bottom: "0px",
});

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme,
  maxHeight: "30px",
  maxWidth: "30px",
  borderRadius: "10px",
  border: "1px solid #BABABA",
  marginLeft: "10px",
}));
