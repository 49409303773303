import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomVendorContainer,
  VendorSubTypography,
} from "../../Utils/VendorStyledTags";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { toast } from "react-toastify";
import { axiosVendorServiceInstance } from "../../../../services/api";
import payhere from "../../../../assets/payhere.png";
import paypal from "../../../../assets/paypal.png";
import stripe from "../../../../assets/stripe.png";
import { digitalPayment } from "../../../../DomainConstants.js";
import noDigitalPaymentMethods from "../../../../assets/noDigitalPaymentMethods.svg";
import { EmptyPageTemplete } from "../../../../layouts/EmptyPageTemplete";
import PaymentMethodSelection from "../Section/PaymentMethodSelection";
import "./Switch/switch.css";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";

export const VendorManageDigitalPaymentMethods = () => {
  const isLargeScreen = useMediaQuery("(min-width:1349px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:710px) and (max-width:1349px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const isFree =
    JSON.parse(localStorage.getItem("user")).subscriptionPlan === "FREE";
  const navigate = useNavigate();
  const [vendor, setVendor] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const isDigitalPaymentNotAvailable =
    !vendor?.paymentMethods || vendor.paymentMethods.length === 0;
  const paymentMethods = !isDigitalPaymentNotAvailable
    ? vendor.paymentMethods
    : null;
  const payHereCredentials = !isDigitalPaymentNotAvailable
    ? vendor.payHereCredentials
    : null;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const checkMark = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M9 4L5 8L3 6"
        stroke="#059669"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const response = await axiosVendorServiceInstance.get(
          `/vendor-service/${vendor.vendorId}`
        );
        setVendor(response.data);
        localStorage.setItem("user", JSON.stringify(response.data));
        if (response.data.subscriptionPlan === "FREE") {
          navigate("/vendor/vendorManagePaymentMethod");
        }
      } catch (err) {
        if (err.response === undefined) {
        } else {
          const errmessage = err.response.data.error;
          console.log(errmessage);
        }
      }
    };
    vendor.vendorId && fetchVendorData();
  }, []);

  const handleClickBackIcon = () => {
    navigate("/vendor/vendorManagePaymentMethod");
  };

  const handleAddDigitalPaymentMethod = (event) => {
    if (JSON.parse(localStorage.getItem("user")).subscriptionPlan === "FREE") {
      toast.error(
        "UPGRADE now to unlock this feature and enhance your experience!"
      );
    } else {
      setDrawerOpen(true);
    }
  };

  const switchHandler = async (event) => {
    try {
      const id = event.target.id;
      const response = await axiosVendorServiceInstance.put(
        `/vendor-service/${vendor.vendorId}/payment-methods/${id}`,
        { active: event.target.checked }
      );
      const updatedVendor = {
        ...vendor,
        paymentMethods: response.data.data.paymentMethods,
      };
      setVendor(updatedVendor);
      localStorage.setItem("user", JSON.stringify(updatedVendor));
      toast.success(
        `${event.target.name} has been ${
          event.target.checked ? "disabled" : "enabled"
        }`
      );
    } catch (err) {
      if (err.response === undefined) {
        alert("Please Try Again Later!");
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  const handleSelection = (method) => {
    let title;
    switch (method.name) {
      case digitalPayment.PAYHERE.toUpperCase():
        title = digitalPayment.PAYHERE;
        break;
      case digitalPayment.PAYPAL.toUpperCase():
        title = digitalPayment.PAYPAL;
        break;
      case digitalPayment.STRIPE.toUpperCase():
        title = digitalPayment.STRIPE;
        break;
    }

    navigate("/vendor/configurePaymentMethod", {
      state: {
        id: method.id,
        title: title,
        status: method.active,
        payHereCredentials: payHereCredentials,
        isEdit: true,
      },
    });
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    background: "var(--white, #FFF)",
    marginTop: isBoth ? "24px" : "70px",
    overflow: "hidden",
    gap: "5px",
  };

  const handleUpgradeButtonClick = () => {
    navigate("/vendor/upgradeSubscription", {
      state: { originPath: "/vendor/vendorManagePaymentMethod" },
    });
  };

  const renderAddNewButton = () => (
    <Box
      sx={{
        position:"absolute",
        bottom:"0",
        left:24,
        right:24,
        marginTop: isBoth ? "0px" : "55px",
        padding: isBoth ? "24px 0 12px 0" : "24px 18px 52px 18px",
      }}
    >
      <Grid
        onClick={handleAddDigitalPaymentMethod}
        sx={{
          width:"100%",
          display: "flex",
          borderRadius: "6px",
          padding: "12px",
          border: "1px solid var(--gray-500, #E52D3C)",
          background: "var(--white, #FFF)",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
          cursor: "pointer",
        }}
      >
        <IconButton
          style={{
            display: "flex",
            padding: "0px 0px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexShrink: "0",
            borderRadius: "8px",
          }}
        >
          <AddRoundedIcon
            style={{
              color: "#E52D3C",
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            color: "var(--gray-500, #E52D3C)",
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "16px",
          }}
        >
          Add new
        </Typography>
      </Grid>
    </Box>
  );
  return (
    <>
      <CssBaseline />
      <main>
        <CustomVendorContainer style={{ background: "#FFFF", position:"relative" }}>
          <Container
            maxWidth="sm"
            style={{
              zIndex: 10,
              padding: "0px",
              width: "100%",
              height: "100%",
            }}
          >
            <Container
              maxWidth={false}
              sx={{
                zIndex: 10,
                padding: "0px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "550px",
                  lg: "600px",
                },
              }}
            >
              <Container
                maxWidth="sm"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  zIndex: 1000,
                  backgroundColor: "#FFF",
                  padding: "0px",
                }}
              >
                <Grid
                  container
                  xs={12}
                  sx={{
                    marginTop: isBoth ? "0px" : "10px",
                    paddingRight: isBoth ? "0px" : "10px",
                  }}
                >
                  {!isBoth && (
                    <Grid item xs={1}>
                      <IconButton
                        color="inherit"
                        onClick={handleClickBackIcon}
                        style={{
                          top: 0,
                          zIndex: 1001,
                        }}
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={isFree ? (isBoth ? 12 : 7.5) : isBoth ? 6 : 7.5}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      sx={{
                        color: "var(--black, #000)",
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "130%" /* 23.4px */,
                        textTransform: "capitalize",
                        marginTop: "10px",
                        justifyContent: "center",
                        textAlign: isBoth ? "left" : "center",
                        marginLeft: isBoth ? "0px" : "65px",
                      }}
                    >
                      Digital Payments
                    </Typography>
                    {isFree && isBoth && (
                      <Button
                        onClick={handleUpgradeButtonClick}
                        style={{
                          height: "40px",
                          width: "120px",
                          borderRadius: "8px",
                          border: "1px solid var(--lighter-grey, #BDBDBD)",
                          padding: "10px 21px",
                          backgroundImage:
                            "linear-gradient(to right, #EC3B43 0%, #F15B61 100%)",
                        }}
                      >
                        <ElectricBoltIcon
                          sx={{ color: "#FFFFFF", fontSize: 20 }}
                        />
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                          Upgrade
                        </Typography>
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={isBoth ? 6 : 3.5}>
                    <PaymentMethodSelection
                      incorporatedMethods={paymentMethods}
                      drawerOpen={drawerOpen}
                      setDrawerOpen={setDrawerOpen}
                      onAddNewClick={handleAddDigitalPaymentMethod}
                    />
                  </Grid>
                </Grid>
              </Container>
              {paymentMethods && paymentMethods.length > 0 ? (
                <>
                  <List sx={style}>
                    {paymentMethods.map((method) => (
                      <>
                        <ListItem
                          sx={{
                            width: "100%",
                            backgroundColor: !method.active
                              ? "#F3F4F6"
                              : "white",
                            borderRadius: "12px",
                            border: "1px solid var(--gray-200, #E5E7EB)",
                            marginBottom: "24px",
                          }}
                        >
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              width: "100%",
                              cursor: "pointer",
                              alignItems: "center",
                            }}
                          >
                            <Grid item xs={12}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid
                                  item
                                  xs={9}
                                  onClick={() => {
                                    handleSelection(method);
                                  }}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Grid container alignItems="center">
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        opacity: method.active ? 1 : 0.5,
                                        filter: method.active
                                          ? "none"
                                          : "grayscale(100%)",
                                      }}
                                    >
                                      {method.name ===
                                        digitalPayment.PAYHERE.toUpperCase() && (
                                        <img src={payhere} alt="PayHere" />
                                      )}
                                      {method.name ===
                                        digitalPayment.PAYPAL.toUpperCase() && (
                                        <img src={paypal} alt="Paypal" />
                                      )}
                                      {method.name ===
                                        digitalPayment.STRIPE.toUpperCase() && (
                                        <img src={stripe} alt="Stripe" />
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        paddingLeft: "10px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          color: "var(--gray-900, #111827)",
                                          fontFamily: "Poppins, sans-serif",
                                          fontSize: "16px",
                                          fontStyle: "normal",
                                          fontWeight: 400,
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {method.name ===
                                        digitalPayment.PAYHERE.toUpperCase()
                                          ? digitalPayment.PAYHERE
                                          : method.name}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      id={method.id}
                                      name={method.name}
                                      checked={method.active}
                                      onChange={switchHandler}
                                    />
                                    <span className="slider round">
                                      {method.active && (
                                        <span className="checkmark">
                                          {checkMark}
                                        </span>
                                      )}
                                    </span>
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </>
                    ))}
                  </List>
                  {renderAddNewButton()}
                </>
              ) : (
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <EmptyPageTemplete
                    image={noDigitalPaymentMethods}
                    heading={"No Digital Payment Methods"}
                    message={
                      "You haven't added any digital payment methods yet. Add a card or link your preferred payment service to make transactions seamless and convenient!"
                    }
                  />
                  {!isFree && renderAddNewButton()}

                  {/* <Box
                    sx={{
                      marginTop: isBoth ? "0px" : "55px",
                      padding: isBoth
                        ? "24px 18px 12px 18px"
                        : "24px 18px 52px 18px",
                    }}
                  >
                    <Grid
                      onClick={handleAddDigitalPaymentMethod}
                      sx={{
                        display: "flex",
                        borderRadius: "6px",
                        padding: "16px",
                        border: "1px solid var(--gray-500, #E52D3C)",
                        background: "var(--white, #FFF)",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <IconButton
                        style={{
                          display: "flex",
                          padding: "0px 0px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          flexShrink: "0",
                          borderRadius: "8px",
                        }}
                      >
                        <AddRoundedIcon
                          style={{
                            color: "#E52D3C",
                            width: "24px",
                            height: "24px",
                          }}
                        />
                      </IconButton>
                      <Typography
                        sx={{
                          color: "var(--gray-500, #E52D3C)",
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "16px",
                        }}
                      >
                        Add new
                      </Typography>
                    </Grid>
                  </Box> */}
                </Container>
              )}
            </Container>
          </Container>
        </CustomVendorContainer>
      </main>
    </>
  );
};
