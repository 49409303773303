export const DIALOG_HEADING_STYLE = {
  maxWidth: "100%",
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "130%",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "20px",
  textTransform: "capitalize",
};

export const DIALOG_CONTENT_STYLE = {
  maxWidth: "100%",
  color: "#7D8488",
  fontFamily: "Poppins",
  fontSize: "16px",
  textAlign: "center",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "150%",
  display: "flex",
  justifyContent: "center",
  marginTop: "40px",
  marginBottom: "40px",
};

export const ROW_FLEX_STYLE = { display: "flex", alignItems: "center" };

export const DEFAULT_TYPOGRAPHY_STYLE = {
  color: "#111827",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: "400",
};

export const DEFAULT_OFFER_CARD_TYPOGRAPHY_STYLE = {
  color: "black",
  fontSize: "12px",
  fontFamily: "Poppins",
  fontWeight: "400",
  wordWrap: "break-word",
  textAlign: "left",
};

export const PERCENTAGE_TYPOGRAPHY_STYLE = {
  color: "black",
  fontSize: 16,
  fontFamily: "Poppins",
  fontWeight: "600",
  wordWrap: "break-word",
};

export const DISCOUNT_DATE_TYPOGRAPHY_STYLE = {
  color: "var(--light-grey, #6C6C6C)",
  textAlign: "left",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  marginTop: "5px",
};

export const OFFER_CODE_TYPOGRAPHY_STYLE = {
  color: "#111827",
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: "500",
  lineHeight: "15px",
  wordWrap: "break-word",
};

export const DATE_STATUS_DIV_STYLE = {
  ...ROW_FLEX_STYLE,
  justifyContent: "space-between",
  marginTop: "32px",
  paddingRight: "27px",
  width: "240px",
};

export const STATUS_BLOCK_STYLE = (props) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: props.offerStatusWidth,
    height: "28px",
    flexShrink: 0,
    borderRadius: "100px",
    marginLeft: "0px",
  };
};

export const DISCOUNT_CODE_BLOCK_STYLE = {
  position: "absolute",
  top: "0px",
  left: "0px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // width: "64px",
  padding: "4px 10px",
  // height: "24px",
  flexshrink: 0,
  borderRadius: "8px 0px 8px 0px",
  backgroundColor: "rgba(0, 0, 0, 0.29)",
  backdropFilter: "blur(10px)",
};

export const DISCOUNT_CODE_STATIC_TYPOGRAPHY_STYLE = {
  display: "flex",
  color: "#FFFFFF",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "8px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "15px", //"187.5%"
};

export const DISCOUNT_CODE_DYNAMIC_TYPOGRAPHY_STYLE = {
  ...DISCOUNT_CODE_STATIC_TYPOGRAPHY_STYLE,
  fontSize: "14px",
};

export const CREATE_ICON_STYLE = {
  maxWidth: "sm",
  flexShrink: 0,
  position: "sticky",
  left: "80%",
  bottom: "25%",
  zIndex: 30,
  padding: "0px",
  margin: "0px",
  backdropFilter: "blur(5px)",
};

export const ADD_MENU_ICON_STYLE = {
  maxWidth: "sm",
  flexShrink: 0,
  position: "sticky",
  left: "75%",
  bottom: "10%",
  zIndex: 1001,
  padding: "px",
  margin: "0px",
  backdropFilter: "blur(20px)",
};

export const ADD_OFFER_ICON_STYLE = {
  maxWidth: "sm",
  flexShrink: 0,
  position: "sticky",
  left: "75%",
  bottom: "10%",
  zIndex: 30,
  padding: "0px",
  margin: "0px",
  backdropFilter: "blur(20px)",
};

export const LABEL_STYLE_1 = {
  maxWidth: "100%",
  color: "#7D8488",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "15px",
  marginBottom: "10px",
};

export const FIELD_STYLE_1 = {
  maxWidth: "100%",
  borderRadius: "9px",
  border: "1px solid #CAC1C1",
  boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
  color: "#263238",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
  marginLeft: "15px",
  marginRight: "15px",
  marginBottom: "10px",
};

export const TO_STYLE_1 = {
  ...LABEL_STYLE_1,
  marginLeft: "1%",
  marginRight: "1%",
};

export const VALID_DATE_TO_FIELD_STYLE_1 = {
  ...LABEL_STYLE_1,
  borderRadius: "9px",
  border: "1px solid #CAC1C1",
  boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
};

export const BOTTOM_NAV_BAR_STYLE = {
  maxWidth: "sm",
  // flexShrink: 0,
  display: "flex",
  justifyContent: "space-between",
  // width: "60%",
  // alignItems: "center",
  backgroundColor: "#FFFFFF",
  position: "fixed",
  bottom: 0,
  // zIndex: 20,
};

export const LABEL_STYLE_2 = {
  maxWidth: "100%",
  color: "var(--gray-700, #374151)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "10px",
  marginBottom: "10px",
};

export const LABEL_STYLE_2_New = {
  maxWidth: "100%",
  color: "#7D8488",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "10px",
  marginBottom: "10px",
};

export const FIELD_STYLE_2 = {
  borderRadius: "9px",
  color: "#263238",
  display: "flex",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "10px",

  "& .MuiOutlinedInput-input": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#CAC1C1",
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Poppins",
  },
};

export const IMAGE_FIELD_STYLE_2 = {
  ...FIELD_STYLE_2,
  width: "96%",
  height: "170px",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  overflow: "hidden",
  border: "2px dashed var(--gray-300, #D1D5DB)",
};

export const DISCOUNT_LABEL_STYLE = { ...LABEL_STYLE_2, marginTop: "25%" };

export const LABEL_STYLE_3 = {
  maxWidth: "100%",
  color: "#7D8488",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "20px",
};

export const FIELD_STYLE_3 = {
  width: "90%",
  // maxWidth: "100%",
  borderRadius: "9px",
  border: "1px solid #CAC1C1",
  color: "#263238",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
  marginLeft: "10px",
  marginRight: "10px",
  marginBottom: "10px",
};

export const SELECT_ALL_FIELD_STYLE = {
  ...FIELD_STYLE_3,
  border: "none",
  marginBottom: "0px",
};

export const STATUS_STYLE = {
  color: "#000000",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

export const STATUS_BLOCK_STYLE_2 = (vendorOfferData) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: vendorOfferData.offerStatusWidth,
    height: "28px",
    flexShrink: 0,
    borderRadius: "100px",
    background:
      vendorOfferData.offerStatus === "Active" ? "#C1E1C8D4" : "#F9B3B3",
  };
};

export const DUAL_LABEL_STYLE = {
  ...LABEL_STYLE_1,
  marginRight: "px",
  marginLeft: "10%",
};

export const DUAL_FIELD_STYLE = {
  ...FIELD_STYLE_1,
  marginRight: "5px",
  marginLeft: "10%",
};

export const TO_STYLE = {
  ...LABEL_STYLE_1,
  marginLeft: "1%",
  marginRight: "1%",
};

export const VALID_DATE_TO_FIELD_STYLE = {
  ...LABEL_STYLE_1,
  marginRight: "5px",
  borderRadius: "9px",
  border: "1px solid #CAC1C1",
  boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
};

export const ITEM_CARD_MAIN_TITLE_STYLE = {
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  textAlign: "left",
};

export const ITEM_CARD_SUB_TITLE_STYLE = {
  color: "var(--light-grey, #6C6C6C)",
  textAlign: "justify",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "16.8px" /* 133.333% */,
};

export const ITEM_TYPE_TYPOGRAPHY_STYLE = {
  color: " var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
  paddingLeft: "5px",
  whiteSpace: "nowrap",
};

export const ITEM_TYPE_ICON_STYLE = {
  color: "white",
  backgroundColor: "var(--green-600, #059669)",
  borderRadius: "50%",
  width: "20px",
  height: "20px",
};

export const ITEM_TYPE_BACKGROUND_STYLE = {
  borderRadius: "50%",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const ITEM_PRICE_TYPOGRAPHY_STYLE = {
  flexShrink: 0,
  color: "var(--gray-900, #111827)",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "normal",
};

export const SUB_TEXT_STYLE = {
  color: "#7D8488",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px" /* 112.5% */,
  marginLeft: "24px",
};

export const VENDOR_PORTAL_TITTLE = {
  color: "var(--gray-500, #6B7280)",
  textAlign: "left",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
};

export const VENDOR_PORTAL_SUB_TITTLE = {
  fontFamily: "Poppins",
  fontSize: "10px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  letterSpacing: "0.1px",
};

export const VENDOR_UPDATE_TIME_HEADING = {
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
};

export const LABEL_STYLE_4 = {
  // maxWidth: "65%",
  color: "var(--dark-grey, #3C3C3C)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "36px",
  display: "flex",
  marginBottom: "12px",
};

export const FIELD_STYLE_4 = {
  height: "44px",
  borderRadius: "8px",
  padding: "10px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  display: "flex",
  marginRight: "20px",
  alignItems: "center",
};

export const LABEL_STYLE_5 = {
  // maxWidth: "100%",
  color: "var(--dark-grey, #3C3C3C)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  // lineHeight: "36px",
  display: "flex",
  // paddingTop: "5px",
};

export const LABEL_STYLE_6 = {
  marginLeft: "11px",
  color: "var(--dark-grey, #3C3C3C)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "130%",
  textTransform: "capitalize",
};

export const FIELD_STYLE_5 = {
  height: "44px",
  borderRadius: "8px",
  padding: "10px",
  border: "1px solid var(--lighter-grey, #BDBDBD)",
  display: "flex",
  alignItems: "center",
};

export const SEARCH_MENU_TYPO = {
  fontFamily: "Poppins",
  color: "var(--gray-500, #6B7280)",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24px",
};

export const CLOSE_SYMBOL = {
  color: "rgba(229, 45, 60, 1)",
  height: "24px",
  width: "24px",
  marginRight: "10px",
};
export const CANCEL_SUBSCRIPTION_POINT = {
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%",
  textTransform: "capitalize",
};

export const CANCEL_SUBSCRIPTION_DIV = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "0px 40px",
  marginTop: "10px",
};

export const BILL_HISTORY_TABLE_TITLE = {
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%",
  textTransform: "capitalize",
};

export const BILL_HISTORY_TABLE_ITEM = {
  color: "var(--black, #000)",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "130%",
  textTransform: "capitalize",
  // textAlign:'left'
};

export const RECEIPT_ITEM_LABEL_ICON = {
  marginRight: "10px",
  width: "24px",
  height: "24px",
  color: "rgba(106, 106, 106, 1)",
};

export const FIELD_STYLE_UNIT = {
  width: "100%",
  borderRadius: "6px",
  border: "1px solid var(--gray-300, #D1D5DB)",
  color: "#263238",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
};

export const LABEL_STYLE_UNIT = {
  width: "100%",
  color: "var(--gray-700, #374151)",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "18px",
  display: "flex",
  alignItems: "flex-start",
  marginLeft: "10px",
  marginBottom: "10px",
};

export const NOTIFY_DELAYED_DELIVERY_AREA_CUSTOM_BOX = {
  display: "flex",
  flexWrap: "wrap",
  gap: "5px",
  width: "100%",
  borderRadius: "8px",
  padding: "10px",
  boxSizing: "border-box",
};

export const ADDITIONAL_CHARGE_FIELD = {
  borderRadius: "6px",
  color: "#263238",
  display: "flex",
  width: "43%",

  "& .MuiOutlinedInput-input": {
    color: "#000",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D5DB",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
    color: "#000",
    fontSize: "14px",
  },
};

export const POS_SEACRH_BAR_STYLE = {
  borderRadius: "6px",
  color: "#263238",
  display: "flex",
  width: "43%",
  marginBottom: "10px",

  "& .MuiOutlinedInput-input": {
    color: "#6B7280",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D1D5DB",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Poppins",
    color: "#000",
    fontSize: "16px",
  },

  "& .MuiOutlinedInput-root": {
    height: "46px", // Set your desired height here
  },
};

export const Link_Card_TextField_Style = {
  maxWidth: "100%",
  borderRadius: "6px",
  border: "1px #E5E7EB solid",
  // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
  color: "#6B7280",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "18px",
  display: "flex",
};

export const Export_Pdf_Subtitle = {
  color: "var(--gray-700, #374151)",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
  textAlign: "left",
  marginTop: "10px",
  marginBottom: "10px",
};

export const Export_Pdf_Outerbox = {
  borderRadius: "6px",
  border: "1px solid var(--gray-300, #D1D5DB)",
  background: "var(--white, #FFF)",
  display: "flex",
  padding: "12px 12px 0px 13px",
  alignItems: "center",
  alignSelf: "stretch",
  flexWrap: "wrap",
  minHeight: "60px",
};

export const Export_Pdf_Innerbox = {
  borderRadius: "28px",
  border: "1px solid var(--gray-600, #4B5563)",
  background: "var(--gray-50, #F9FAFB)",
  display: "flex",
  flexShrink: 0,
  padding: "2px 4px 2px 8px",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "10px",
  marginBottom: "10px",
};

export const Export_Pdf_Tagname = {
  color: "var(--gray-600, #4B5563)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  marginRight: "6px",
};
